<template>
  <div class="landingPage container-xl mt-3">
    <div v-if="user" class="row">
      <div class="col-12">
        <div class="card rounded-lg">
          <div class="card-body">
            <div class="d-flex justify-content-end">
              <b-button size="sm" class="mb-3" @click="showCreateForm = true">Create New Landing Page +</b-button>
            </div>
           <div>
             <div v-if="landingPages.length" class="tablerounededCorner">
               <table class="rounded-3 table table-striped roundedTable">
                 <thead>
                 <tr>
                   <th>ID</th>
                   <th>Date</th>
                   <th>Name</th>
                   <th style="width: 200px;"></th>
                 </tr>
                 </thead>
                 <tbody>
                 <tr v-for="page in landingPages" :key="page.id">
                   <td>{{page.id}}</td>
                   <td>{{page.front_date}}</td>
                   <td>{{page.name}}</td>
                   <td style="width: 200px;">
                     <router-link :to="{ name: 'agency.le-landing-page', params: { id: page.id } }" class="btn btn-sm btn-success">Edit</router-link>
<!--                     <b-button
                         variant="link"
                         title="Delete"
                         size="lg"
                         class="btn-delete text-danger py-0"
                         :disabled="loadingRemove === page.id"
                         @click="remove(page)"
                     >
                       <b-spinner v-if="loadingRemove === page.id" small />
                       <i v-else class="uil uil-trash-alt"></i>
                     </b-button>-->
                   </td>
                 </tr>
                 </tbody>
               </table>
             </div>
             <div v-else class="text-center">
               <b-spinner v-if="isBusy" />
               <span v-else>Create a new Landing Page to get started!</span>
             </div>
           </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showCreateForm" :hide-footer="true" size="sm" title="New Landing Page">
      <ValidationObserver
          v-slot="{ handleSubmit, invalid, touched }"
          ref="landingPageForm"
      >
        <form
            class="landing-page-form d-flex flex-column"
            @submit.prevent="handleSubmit(createLandingPage)"
        >
          <TextInput v-model="newLandingPageName" name="name" label="Template Name" rules="required" />
          <b-form-group>
            <b-button
                variant="success"
                :disabled="isBusy || (invalid && touched)"
                type="submit"
                class="w-100"
            >
              <b-spinner v-if="isBusy" small />
              <span v-else>Submit</span>
            </b-button>
          </b-form-group>
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal v-model="showDeleteModal" title="Delete Landing Page">
      <p v-if="selectedLandingPage"
      >Are you sure you want to delete landing page "{{
          selectedLandingPage.name
        }}"?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
        >Cancel</b-button
        >
        <b-button variant="danger" @click="handleRemove">Delete</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
export default {
  data() {
    return {
      user: {
        agency: {
          whitelabel: {},
          landing_page: {}
        },
      },
      total:0,
      showCreateForm:false,
      newLandingPageName:null,
      isBusy:false,
      apiLoaded:false,
      landingPages:[],
      loadingRemove:null,
      showDeleteModal:false,
      selectedLandingPage:null,
      retrievedData:false
    }
  },
  computed: {
  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user'])
    this.getLandingPages();
  },
  methods: {
    createLandingPage(){
      this.isBusy = true;
      this.$store
          .dispatch('leads/createNewLandingPage', {
            agency_id:this.user.agency.id,
            name:this.newLandingPageName
          })
          .then((resp) => {
            this.isBusy = false;
            // resp.data.id
            // Redirect to Edit Page
            this.$router.push({name:'agency.le-landing-page',params:{id:resp.data.id}});
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.landingPageForm.setErrors(err.response.data.errors)
            }
            this.isBusy = false
          })
    },
    getLandingPages(){
      this.isBusy = true;
      this.$store
          .dispatch('leads/getLandingPages',this.user.agency.id)
          .then((resp) => {
            this.total = resp.total;
            this.landingPages = resp.data;
            this.isBusy = false;
            // resp.data.forEach(search => this.searches.push(search));
          })
          .catch((err) => {
            this.isBusy = false;
            console.log(err);
          })
    },
    remove(page) {
      this.showDeleteModal = true;
      this.selectedLandingPage = page;
    },

    handleRemove() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedLandingPage.id
      this.$store
          .dispatch('leads/deleteLandingPage', this.selectedLandingPage.id)
          .then(() => {
            this.getLandingPages();
          })
          .catch(() => {
            this.loadingRemove = null
          })
    },
  },
}
</script>
<style lang="scss">
.tablerounededCorner {
  border: 1px solid #ccc;
  border-radius: 10px;
}
.roundedTable thead tr:last-child th{
  border-bottom: 1px solid #ccc;
}
.roundedTable {
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin: 0;
}

.roundedTable th{
  padding: .6em;
}

.roundedTable td {
  padding: .4em;
}

.roundedTable th {
  text-align: left;
}

.roundedTable tr:last-child td {
  border-bottom: none;
}
.roundedTable.table-striped tbody tr:nth-of-type(odd) {
  background-color: #eceff6;
}

</style>
